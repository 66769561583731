<template>
    <div id="updateLog">
        <div class="videoimg">
            <div class="videoimg-inner">更新日志</div>
        </div>
        <div class="container">
            <div class="con" v-for="(item, index) in dataList" :key="index">
                <div class="con-time">
                    <p class="con-month">{{ item.month }}月{{ item.day }}日</p>
                    <p class="con-year">{{ item.year }}年</p>
                </div>
                <div class="con-list">
                    <ul v-for="(it, ix) in item.records" :key="ix">
                        <li class="update-list">
                            <span>{{ ix + 1 }}. </span>
                            <pre style="padding: 0px; margin: 0px">{{
                                it.upgradeContent
                            }}</pre>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "updateLog",
    data() {
        return {
            dataList: [],
        };
    },
    methods: {},
    mounted() {
        var data = {
            queryInfo: {
                pageNum: 1,
                pageSize: 9999,
                filters: [],
                sorters: [
                    {
                        sortField: "createTime",
                        direction: "DESC",
                    },
                ],
            },
        };
        this.api
            .postFormAPISM(
                data,
                "/v1.0/uap/data_service/functionUpdate/queryFunctionUpdate"
            )
            .then((res) => {
                if (res.data.return_code == "LAB-0000000") {
                    this.dataList = res.data.data;
                } else {
                }
            })
            .catch((err) => console.log(err));
    },
};
</script>
<style scoped lang="less">
.videoimg {
    width: 100%;
    height: 126px;
    background-image: linear-gradient(538deg, #04a39b, #027570) !important;
    margin: 0 auto;

    .videoimg-inner {
        width: 960px;
        height: 126px;
        margin: 0 auto;
        color: white;
        font-size: 35px;
        padding-top: 40px;
        padding-left: 140px;
    }
}

.container {
    width: 900px;
    height: auto;
    margin: 50px auto 0;
    padding-left: 60px;
    min-height: 400px;

    .con {
        overflow: hidden;
        padding-left: 14px;
        padding-bottom: 24px;
        border-bottom: #dfe2e5 1px solid;
        margin-bottom: 50px;
    }

    .con-time {
        float: left;
        border-left: 2px solid #2294ff;
        padding-left: 6px;
    }

    .con-month {
        color: #000000;
        font-size: 18px;
    }

    .con-year {
        font-size: 14px;
    }

    .con-list {
        color: #808080;
        font-size: 14px;
        margin-left: 110px;
    }

    .clear {
        clear: both;
    }

    ul,
    ol {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }

    .update-list {
        line-height: 34px;
        display: flex;

        span {
            padding-right: 8px;
        }
    }
}</style>
